import { computed, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { EMPTY, map, Observable, of, skipWhile, switchMap } from 'rxjs';
import { Router } from '@angular/router';

import { OidcUserModel } from '~/api/auth/oidc-user.model';

const RETURN_URL_KEY = 'gp-return-url';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private oidc = inject(OidcSecurityService);
  private router = inject(Router);

  readonly userData$: Observable<OidcUserModel> = this.oidc.userData$.pipe(
    skipWhile(({ userData }) => !userData),
    map(({ userData }) => userData),
  );

  readonly currentUser = toSignal(this.userData$);

  readonly userRoles = computed(() => {
    const { role } = this.currentUser() ?? {};

    if (!role) {
      return [];
    }

    return Array.isArray(role) ? role : [role];
  });

  name = computed(() => this.currentUser()?.name ?? 'N/A');

  checkAuth() {
    return this.oidc.checkAuth().pipe(
      switchMap((response) => {
        return this.handleCheckAuth(response);
      }),
    );
  }

  authorize() {
    return this.oidc.authorize();
  }

  logout() {
    return this.oidc.logoff();
  }

  private handleCheckAuth(response: LoginResponse): Observable<LoginResponse> {
    if (response.isAuthenticated) {
      const url = sessionStorage.getItem(RETURN_URL_KEY);

      if (url) {
        sessionStorage.removeItem(RETURN_URL_KEY);
        this.router.navigateByUrl(url).then();
        return EMPTY;
      }
    } else {
      sessionStorage.setItem(RETURN_URL_KEY, location.pathname);
    }

    return of(response);
  }
}
